.scene {
  &--1 {
    background-image: url("../../../assets/images/scenes/scene-1.png");
    background-size: cover;
    @media (max-width: 770px) {
      background-position-x: 73%;
    }
  }
  &--2 {
    background-image: url("../../../assets/images/scenes/scene-2.png");
    background-size: cover;
  }
  &--3 {
    background-image: url("../../../assets/images/scenes/scene-3.png");
    background-size: cover;
  }
  &--6 {
    background-image: url("../../../assets/images/scenes/scene-6.png");
    background-size: cover;
    @media (max-width: 770px) {
      background-position: center;
    }
  }
  &--7 {
    background-image: url("../../../assets/images/scenes/scene-7.png");
    background-size: cover;
  }
  &--8 {
    background-image: url("../../../assets/images/scenes/scene-8.png");
    background-size: cover;
    @media (max-width: 770px) {
      background-position: center;
    }
  }
  &--9 {
    background-image: url("../../../assets/images/scenes/scene-9.png");
    background-size: cover;
    @media (max-width: 770px) {
      background-position: center;
    }
  }
  &--10 {
    background-image: url("../../../assets/images/scenes/scene-10.png");
    background-size: cover;
    @media (max-width: 770px) {
      background-position: center;
    }
  }
}
